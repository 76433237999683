import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import VueMeta from 'vue-meta'
import store from './store'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import VueGtag from './packages/lnw-vue-gtag'
import VueClipboard from 'vue-clipboard2';

import './scss/main.scss';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(vueAwesomeCountdown, 'vac')
// Vue.use(VueMeta, {
//   // optional pluginOptions
//   refreshOnceOnNavigation: true,
// });

const config_GTAG = require('@/config/gtag.json');
Vue.use(VueGtag, {
  config: {
    id: config_GTAG.MEASUREMENT_ID,
  },
});

import VuePixel from './packages/lnw-vue-pixel'
import i18n from './i18n'
Vue.use(VuePixel, {bootstrap: false});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');