import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#007AFF',
                themeColor: '#FEFEFF',
                secondary: '#b2ad7f',
                accent: '#40c880',
                error: '#b71c1c',
            },
            dark: {
                primary: '#0A84FF',
                themeColor: '#1E1E1E',
            },
        },
        options: {
            customProperties: true,
        },
    },
});