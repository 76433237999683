/**
 * ใน PWA เราอยากใช้ localStorage เพื่อให้มันยังเก็บสถานะการล็อคอินเอาไว้ ถึงแม้ปิดแอพไปแล้ว
 * โค้ดนี้จะตรวจสอบโหมดการทำงานของแอป และจัดการกับการเก็บข้อมูลการล็อกอินให้เหมาะสม
 */

const isPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
const primaryStorage = isPWA ? 'localStorage' : 'sessionStorage';
const fallbackStorage = isPWA ? 'sessionStorage' : 'localStorage';

// โอนย้ายข้อมูลจาก sessionStorage ไปที่ localStorage เมื่อเข้า PWA Mode
if (isPWA && sessionStorage.getItem('lnw_access_token')) {
  localStorage.setItem('lnw_access_token', sessionStorage.getItem('lnw_access_token'));
  sessionStorage.removeItem('lnw_access_token');
}

function setAccessToken(accessToken) {
  if (window[primaryStorage]) {
    window[primaryStorage].setItem('lnw_access_token', accessToken);
  } else if (window[fallbackStorage]) {
    window[fallbackStorage].setItem('lnw_access_token', accessToken);
  }
}

function getAccessToken() {
  if (window[primaryStorage]) {
    return window[primaryStorage].getItem('lnw_access_token');
  } else if (window[fallbackStorage]) {
    return window[fallbackStorage].getItem('lnw_access_token');
  }
  return null;
}

function clearAccessToken() {
  if (window[primaryStorage]) {
    window[primaryStorage].removeItem('lnw_access_token');
  } else if (window[fallbackStorage]) {
    window[fallbackStorage].removeItem('lnw_access_token');
  }
}

export {
  getAccessToken,
  clearAccessToken,
  setAccessToken,
};