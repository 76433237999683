import { get_gtag_ids_list } from "./helpers.js";

const config_GTAG = require("@/config/gtag.json");
const config_lnwpay = require('@/config/lnwpay.json');
const { shop_product_to_google_item, mall_product_to_google_item, ucwords } = require("@/libraries/Utilities.js");

/**
 * @param state
 * @returns {*[]|*}
 */
const shop_line_tag_ids = (state) => {
  if (state.shopData && state.shopData.line_tag_ids && Array.isArray(state.shopData.line_tag_ids)) {
    return state.shopData.line_tag_ids;
  }
  return [];
};

/**
 * @param state
 * @returns {*[]|*}
 */
const shop_tiktok_pixel_ids = (state) => {
  if (state.shopData && state.shopData.tiktok_pixel_ids && Array.isArray(state.shopData.tiktok_pixel_ids)) {
    return state.shopData.tiktok_pixel_ids;
  }
  return [];
};

const shopUrlForTrackEvent = (state, event) => {
  const shopData = state.shopData;

  if (shopData) {
    const order_id = state.order_id;
    const secret = state.secret;
    const type = state.type; // order | cart
    return `${shopData.url}events/${event}/${order_id}/${secret}/${type}`;
  }

  return null;
};

// const created_time = (new Date()).getTime();

export default {
  isCart: state => state.type === 'cart',
  orderApiUrlParams: (state) => {
    return {
      web_name: state.web_name,
      order_id: state.order_id,
      secret: state.secret,
      type: state.type,
    };
  },
  shopApiUrlParams: (state) => {
    return {
      web_name: state.web_name,
    }
  },
  stepMapping: (state, getters) => {
    if (getters.order?.order_status === 'wait_order'){
      return {
        buyerInfo: 1,
        shippingMethod: 2,
        paymentMethod: 3,
      };
    }else{
      if(state.orderData?.order_payment || getters.isShippingIsLastStep){
        return {
          shippingMethod: 1,
          paymentMethod: 2,
          buyerInfo: 3,
        };
      }else{
        return {
          paymentMethod: 1,
          shippingMethod: 2,
          buyerInfo: 3,
        };
      }
    }
  },
  access_token: (state, getters, rootState) => rootState.user.access_token,
  axios_headers: (state, getters, rootState, rootGetters) => {
    return rootGetters["user/axios_headers"]
  },
  order: (state) => state.orderData,
  form: (state) => state.form,
  shop: (state) => state.shopData,
  mall: (state) => state.mallData,

  //เพราะเว็บ lnwx ที่ควรเป็น shop แต่ส่งมาเป็น mall
  isRealMall: (state) => state.mallData && state.mallData.key === 'pubat',

  orderLanguage: (state) => state.orderData?.lang_key,
  gointer: (state) => state.shopData ? !!state.shopData.gointer : false,

  custom_forms: state => Array.isArray(state.orderData.custom_forms) ? state.orderData.custom_forms : [],
  custom_datas: state => state.orderData.custom_datas === null || typeof state.orderData.custom_datas !== 'object' ? {} : state.orderData.custom_datas,

  //quotation
  isQuotationNoteEnabled: (state) => (state.orderData.is_quotation_note_enabled) ? true : false,

  //LINE Notify
  isLineNotifyEnabled: (state, getters) => (getters.shop.line_notify) ? true : false,

  // Facebook Checkbox Plugin
  isFacebookCheckboxPluginEnabled: (state, getters) => (getters.shop.fb_checkbox_plugin && getters.shop.fb_checkbox_plugin.page_id) ? true : false,
  facebookCheckboxPluginPageId: (state, getters) => getters.shop.fb_checkbox_plugin ? getters.shop.fb_checkbox_plugin.page_id : null,
  facebookCheckboxPluginAppId: (state, getters) => getters.shop.fb_checkbox_plugin ? getters.shop.fb_checkbox_plugin.app_id : null,
  facebookCheckboxPluginRef: (state, getters, rootState, rootGetters) => `${state.shopData.web_id}_${state.type}_${state.order_id}_${state.secret}_${rootGetters["user/loggedInUserId"]}_${getters.facebookCheckBoxPluginRenderedTime}`,
  facebookCheckboxPluginUserRef: (state, getters, rootState, rootGetters) => `${state.shopData.web_id}_${state.type}_${state.order_id}_${state.secret}_${rootGetters["user/loggedInUserId"]}_${getters.facebookCheckBoxPluginRenderedTime}`,
  facebookCheckBoxPluginRenderedTime: state => state.facebook_checkbox_plugin_checkbox_rendered_time,
  facebookCheckboxPluginCheckboxState: state => state.facebook_checkbox_plugin_checkbox_state,

  isFacebookCheckboxRequestOptin: (state, getters) => (getters.shop.fb_checkbox_request_optin) ? true : false,

  /**
   * ดึง gtag_ids ในฝั่งร้านค้า
   * @param state
   * @param getters
   * @returns {[]}
   */
  shop_gtag_ids: (state, getters) => {
    // gtag_ids จะได้ gtag_ids และ analytics_ids ของ shop ออกมา
    const gtag_ids = get_gtag_ids_list(getters, "shop");

    // UUID: a20e9e32-ec2a-418a-a8f2-dfc1da0b67b0
    // ถ้าเป็น key: lnwshop , lnwx ให้ทำงานเสมือนเป็น groups: shop เลย
    if (getters.mall) {
      if (getters.mall.key === "lnwshop" || getters.mall.key === "lnwx") {
        gtag_ids.push(...get_gtag_ids_list(getters, "mall"));
      }
    }

    // ถ้า bill.lnw.me มี tracker ของตัวเอง ก็ใส่เข้าไปด้วย
    if (config_GTAG.MEASUREMENT_ID) {
      gtag_ids.push(config_GTAG.MEASUREMENT_ID);
    }

    return gtag_ids;
  },
  shop_gtag_linker_domains: (state) => {
    if (state.shopData?.cross_domain_linker_domains && Array.isArray(state.shopData?.cross_domain_linker_domains))
      return state.shopData.cross_domain_linker_domains;

    return [];
  },
  shop_facebook_pixel_ids: state => {
    const pixels = [];
    const pixel_ids = (state.shopData?.pixel_ids) ? state.shopData?.pixel_ids : state.shopData?.facebook_pixel_ids;
    if (pixel_ids) {
      for (const id of pixel_ids) {
        pixels.push(id);
      }
    }
    // backward compatability
    else if (state.shopData?.facebook_pixel_id) {
      pixels.push(state.shopData.facebook_pixel_id);
    }

    return pixels;
  },

  PDPA_consent_analytics_storage: state => !! state.orderData?.cookie_consents?.analytics_storage,
  PDPA_consent_ad_storage: state => !! state.orderData?.cookie_consents?.ad_storage,
  // กรณีที่ยอมรับทั้ง analytics_storage และ ad_storage
  PDPA_consent_both: (state, getters) => getters.PDPA_consent_analytics_storage && getters.PDPA_consent_ad_storage,

  any_GA_MEASUREMENT_ID_that_can_get_client_id: (state, getters) => {
    const gtag_ids = getters.shop_gtag_ids.concat(getters.mall_gtag_ids);
    for (const id of gtag_ids) {
      if (id.match(/^UA-/) || id.match(/^G-/))
        return id;
    }
    return false;
  },

  shop_line_tag_ids,
  shop_tiktok_pixel_ids,

  /**
   * gtag_ids ไรบ้าง ที่ต้องยิงด้วย payload แบบ Mall
   * @param state
   * @param getters
   * @returns {*[]}
   */
  mall_gtag_ids: (state, getters) => {
    if (!getters.mall
      // เนื่องจาก key lnwshop,lnwx ตัว tracker-id ถูก return ไปใน function shop_gtag_ids แล้ว UUID: a20e9e32-ec2a-418a-a8f2-dfc1da0b67b0
      || getters.mall.key === "lnwshop" || getters.mall.key === "lnwx"
    ) {
      return [];
    }

    return get_gtag_ids_list(getters, 'mall');
  },
  mall_gtag_linker_domains: (state) => {
    if (state.mallData.cross_domain_linker_domains && Array.isArray(state.mallData.cross_domain_linker_domains))
      return state.mallData.cross_domain_linker_domains;

    return [];
  },
  mall_facebook_pixel_ids: state => {
    const pixels = [];
    const pixel_ids = (state.mallData?.pixel_ids) ? state.mallData?.pixel_ids : state.mallData?.facebook_pixel_ids;

    if (pixel_ids) {
      for (const id of pixel_ids) {
        pixels.push(id);
      }
    }

    return pixels;
  },

  both_facebook_pixel_ids: (state, getters) => {
    const pixels = [];
    // # Shop
    for (const id of getters.shop_facebook_pixel_ids) {
      pixels.push(id);
    }
    // # Mall
    for (const id of getters.mall_facebook_pixel_ids) {
      pixels.push(id);
    }
    return pixels;
  },

  paymentConfigs: (state) => state.orderData && state.orderData.payment_methods ? state.orderData.payment_methods : [],
  bankPaymentMethod: (state, getters) => {
    for (const method of getters.paymentConfigs) {
      if (method.key === 'bank_transfer')
        return method;
    }
    return false;
  },
  thaiQrPaymentMethod: (state, getters) => {
    for (const method of getters.paymentConfigs) {
      if (method.key === 'thai_qr_payment')
        return method.providers;
    }
    return [];
  },
  billPaymentMethod: (state, getters) => {
    for (const provider of getters.thaiQrPaymentMethod) {
      if (provider.detail.account_key === 'billpayment')
        return provider;
    }
    return false;
  },
  // รายชื่อธนาคารที่รับโอนเงิน : สำหรับใช้เป็นตัวเลือก เวลาแจ้งโอนเงิน
  bankPaymentMethodDropdownOptions: (state, getters) => {
    const banks = getters.bankPaymentMethod.providers;
    let bill_bank = getters.thaiQrPaymentMethod;
    for (let i in bill_bank) {
      bill_bank[i].key = bill_bank[i].key.replace("billpayment", "bank_transfer").replace("promptpay", "bank_transfer");
      banks.push(bill_bank[i]);
    }
    for (let i in banks) {
      banks[i].dropdown_select_text = `${banks[i].name} (${banks[i].detail.account_number})`;
      banks[i].dropdown_text = `<span class="d-inline-block">${banks[i].detail.account_number}</span> · ${banks[i].detail.account_name}`;
      banks[i].dropdown_value = banks[i].key;
    }
    return banks;
  },
  paymentProfiles: (state) => state.orderData && state.orderData.payment_profiles ? state.orderData.payment_profiles : [],

  creditCardMethod: (state, getters) => {
    for (const method of getters.paymentConfigs) {
      if (method.key === 'credit_card')
        return method;
    }
    return null;
  },
  creditCardProvider: (state, getters) => getters.creditCardMethod?.provider || null,
  creditCardIsTest: (state, getters) => getters.creditCardMethod?.IS_TEST || false,
  creditCardJsSDK: (state, getters) => getters.creditCardMethod?.js_sdk || null,
  creditCardPaymentAuthURL2c2p: (state, getters) => getters.creditCardMethod?.payment_auth_url || null,

  appliedCoupons: (state) => {
    if (!state.orderData || state.orderData.coupons === false)
      return [];
    else
      return state.orderData.coupons;
  },
  getShopFileNode: (state) => state.shopData ? state.shopData.file_node : null,

  isOrderNoShipping : (state) => { // order ไม่มีการจัดส่ง
    if (state.orderData.is_no_shipping // order ถูกเลือกว่าไม่มีการจัดส่งจากหน้าสร้าง order
        || state.orderData.is_product_no_shipping // ทุก product ถูกตั้งค่าว่าไม่จัดส่ง
    ) {
      return true;
    }
    return false;
  },
  isOrderNoShippingType : (state) => { // order ไม่ต้องเลือกวิธีจัดส่ง (จัดส่งแยก)
    if (state.orderData.is_product_no_shipping_type
    ) {
      return true;
    }
    return false;
  },
  isWaitingPacking : (state,getters) => { // order อยู่ในสถานะกำลังแพ็ค และจัดส่งไปสาขาเพื่อให้มาเข้ารับ (รอจัดส่ง และมีวิธีจัดส่งแบบเข้ารับ)
    if ((state.orderData?.order_status === 'wait_send')
        && getters.isOrderPickupType) {
      return true;
    }
    return false;
  },
  isWaitingPickup : (state,getters) => { // order อยู่ในสถานะรอเข้ารับ (จัดส่งมาที่สาขาแล้ว และมีวิธีจัดส่งแบบเข้ารับ)
    if ((state.orderData?.order_status === 'completed' || state.orderData?.order_status === 'sent')
        && getters.isOrderPickupType) {
      return true;
    }
    return false;
  },
  isOrderPickupType : (state,getters) => { // order เป็นแบบเข้ารับด้วยตัวเอง
    if (state.orderData.is_pickup_shipping ||
        getters.selectedShipping.pickup_type) {
      return true;
    }
    return false;
  },
  selectedShipping: (state) => {
    const selected_id = state.form.shipping_type_id;
    if (state.orderData.shipping_types.hasOwnProperty(selected_id)) {
      return state.orderData.shipping_types[selected_id];
    }
    // กรณีเป็น order ที่มาจาก LSJ ไม่ได้เลือกวิธีจัดส่งผ่าน LnwShop
    if (state.orderData.order_shipping) {
      return state.orderData.order_shipping;
    }
    return false;
  },
  sortedShippingTypes: (state) => {
    const sorted = [];

    if (state.orderData && state.orderData.shipping_types_rank && state.orderData.shipping_types) {
      for (const k of state.orderData.shipping_types_rank) {
        if (state.orderData.shipping_types.hasOwnProperty(k)) {
          sorted.push(state.orderData.shipping_types[k]);
        }
      }
    }

    return sorted;
  },
  isBuyerEnterBasicInfo: (state) => state.buyerInfo && state.buyerInfo.email.length > 0 && state.buyerInfo.mobileNumber.length > 0,

  myCoupons: state => state.orderData ? state.orderData.mycoupons : [],

  canReceiveSms: state => state.orderData ? !!state.orderData.can_receive_sms : false,

  isRequiredLogin: state => state.orderData ? !!state.orderData.is_required_login : false,
  isRequiredJoin: state => state.orderData ? !!state.orderData.is_required_join : false,
  isShowFormJoin: (state, getters) => getters.isRequiredJoin && !getters.isUserJoin,
  isUserJoin: state => state.orderData ? !!state.orderData.is_user_join : false,
  isUserFollow: state => state.orderData ? !!state.orderData.is_user_follow : false,
  isDropshipActive: state => state.orderData ? !!state.orderData.is_dropship_active : false,
  isUserLogin: state => state.orderData ? state.orderData.user?.user_id > 0 : false,
  isOwnerCreateFlag: state => state.orderData ? state.orderData.owner_create_flag > 0 : false,

  isRequiredContactEmail: state => state.orderData ? !!state.orderData.is_required_contact_email : false,
  isRequiredContactMobile: state => state.orderData ? !!state.orderData.is_required_contact_mobile : false,

  isRequiredReceiver: state => state.orderData ? !!state.orderData.is_required_receiver : false,
  isRequiredInformPaymentFile: state => state.orderData ? !!state.orderData.is_required_informpayment_file : false,

  // เลือกชำระโดยการ โอนเงินเข้าบัญชีธนาคาร
  isBankTransferPaymentProvider: (state, getters) => state.orderData.payment_provider
    && (
      /^bank_transfer/.test(state.orderData.payment_provider)
      ||
      /^promptpay/.test(state.orderData.payment_provider)
      ||
      /^billpayment/.test(state.orderData.payment_provider)
      ||
      (getters.isProtectedByLnwPay && ['kbank', 'scb', 'ktb', 'bbl'].includes(state.orderData.payment_provider))
    ),

  isBuyerInfoHasEnoughData: (state, getters) => {
    return (
      getters.order
      && (getters.order.contact_email || getters.order.contact_mobile)
      && (!getters.isRequiredContactEmail || getters.order.contact_email)
      && (!getters.isRequiredContactMobile || getters.order.contact_mobile)
    );
  },
  isPassStepBuyerInfo: (state, getters) => {
    // console.log(getters.isRequiredContactEmail);
    // console.log(getters.isRequiredContactMobile);
    if (state.orderData
      && getters.isBuyerInfoHasEnoughData
      && (!getters.isRequiredJoin || getters.isUserJoin)
      && (!getters.isRequiredLogin || getters.isUserLogin)
    )
      return true;

    if (state.orderData?.order_status !== 'wait_order')
      return true;

    return (
      state.form
      && state.form.buyerInfoConfirmed
      && (
        state.form.forceAsGuest
        || state.form.buyerInfoAlreadyLogin
      )
    );
  },
  isPassReceiverContact: (state, getters) => state.orderData
      && (
          getters.isOrderNoShipping || //ไม่มีการจัดส่ง
          (
              state.orderData.receiver_id > 0 // ระบุที่อยู่จัดส่งแล้ว
                  || state.orderData.receiver_id === -2 // ระบุที่อยู่จัดส่งแล้ว)
          )
      ),
  isPassStepShippingMethod: (state, getters) => state.orderData
    && (
      getters.isOrderNoShipping || //ไม่มีการจัดส่ง
      ( // ตรงนี้เกี่ยวกับ receiver
        (state.orderData.receiver_id > 0 // ระบุที่อยู่จัดส่งแล้ว
            || state.orderData.receiver_id === -2 // ระบุที่อยู่จัดส่งแล้ว
            || (!getters.isRequiredReceiver
                && state.orderData.skip_receiver_flag
                && state.orderData.order_status == 'wait_order') //ระบุว่าข้าม กรณียังรอยืนยันรายการ
            || (getters.isOrderPickupType
                && !getters.isRequiredReceiver)) // ระบุเป็นวิธีการเข้ารับสินค้า
        // ตรงนี้เกี่ยวกับ shipping methods
        && ( state.orderData.shipping_type_id > 0 // เลือกวิธีจัดส่งแล้ว
            || getters.isOrderNoShippingType ) // ข้ามการเลือกวิธีจัดส่ง - ในกรณีสินค้าทั้งหมดในตะกร้าถูกคิดค่าส่งแยกพิเศษ ทำให้ไม่ต้องเลือกวิธีจัดส่ง
      )
    ), // ระบุว่าจะจัดส่งด้วยช่องทางไหนแล้ว
  isPassStepPaymentMethod: (state, getters) => {
    // ถ้า order หมดอายุให้ถือว่า Pass ไปแล้วด้วยเลย
    if (state.orderData && state.orderData.order_status === 'expired')
      return true;

    return getters.isPaidOrPaymentInformed;
  },
  //ไว้เช็คว่ายังเหลือ shipping ที่ยังไม่ผ่านอันสุดท้าย
  isShippingIsLastStep : (state, getters) => {
    if( state.orderData?.order_status !== 'wait_order'
        && getters.isPassStepPaymentMethod
        && !getters.isPassStepShippingMethod) {
      return true;
    }
    return false;
  },
  //ไว้เช็คว่าเป็นสถานะ "รอที่อยู่ผู้รับ" หรือเปล่า (จะเป็นได้เฉพาะ รอจัดส่ง ที่ไม่มีที่อยู่ผู้รับเท่านั้น )
  isWaitReceiverInfo : (state,  getters) => {
    if ((state.orderData?.order_status === 'wait_send')
        && getters.canEditShippingMethod
        && !getters.isPassStepShippingMethod) {
      return true;
    }
    return false;
  },
  //ไว้เช็คว่าเป็นสถานะ "รอร้านค้าคอนเฟิร์ม" (ราคาค่าจัดส่ง) หรือเปล่า (จะเป็นได้เฉพาะรอชำระเงินที่ยังไม่มีค่าส่งเท่านั้น)
  isWaitingShippingPrice: (state) => {
    if (state.orderData && state.orderData.order_status === 'wait_payment') {
      return !!state.orderData.is_wait_confirm_shipping_price;
    }
    return false;
  },
  //ไว้เช็คว่าควรแสดงกล่อง order detail ไว้ด้านบนหรือไม่ โดยเพิ่มถ้าเป็นการสร้าง order จากหลังร้าน order detail ควรจะอยู่ตำแหน่งบนเหมือนตอน cart (เพราะลูกค้าเพิ่งเข้าครั้งแรก) --  แก้ไขจากกรณีเดิมที่เพียงแค่แสดง onTop แต่จะเปลี่ยนให้แสดงเป็นเต็มรูปแบบ
  isShowOrderDetailOnTop : (state,getters) => {
    if( state.orderData?.order_status === 'wait_order'
        || (state.orderData?.order_status === 'wait_payment' && getters.isOwnerCreateFlag)
        || (getters.isWaitReceiverInfo  && getters.isOwnerCreateFlag)){
      return true;
    }
    return false
  },

  isPassAllStep : (state, getters) => {
    if (state.orderData
        && getters.isPassStepBuyerInfo
        && getters.isPassStepShippingMethod
        && getters.isPassStepPaymentMethod) {
      return true;
    }
    return false;
  },

  canEditBuyerInfo: (state) => {
    if (state.orderData)
      return !!state.orderData.can_edit_buyer_info;

    return false;
  },
  canEditShippingMethod: (state, getters) => {
    return getters.canEditShippingType || getters.canEditReceiverContact;
  },
  canEditShippingType: (state) => {
    if (state.orderData)
      return !!state.orderData.can_edit_shipping_type;

    return false;
  },
  canEditReceiverContact: (state) => {
    if (state.orderData) {
      return !!state.orderData.can_edit_receiver_contact;
    }

    return false;
  },
  canEditPaymentMethod: (state) => {
    if (state.orderData) {
      return state.orderData.order_status === 'wait_order'
        || state.orderData.order_status === 'wait_payment';
    }
    return false;
  },
  canSetUser: (state) => !!state.orderData?.can_set_user,
  canUnsetUser: (state) => !!state.orderData?.can_unset_user,

  canAddCoupon: (state) => {
    return !!state.orderData.can_edit_coupon;
  },
  canRemoveCoupon: (state, getters) => {
    return getters.canAddCoupon;
  },

  canReInformPayment: (state, getters) => {
    return ['wait_payment', 'wait_payment_verify'].includes(state.orderData.order_status) && !getters.isProtectedByLnwPay;
  },

  canEditProductDetail: (state) => {
    return state.orderData && state.orderData.can_edit_product_detail;
  },
  canEditProductQuantity: (state) => {
    return state.orderData && state.orderData.can_edit_product_quantity;
  },

  canEditOrderDetail: (state) => {
    return state.orderData && state.orderData.can_edit_order_detail;
  },
  canCancelOrder: (state) => {
    return state.orderData && (
      typeof state.orderData.can_cancel_order === 'undefined' // ทำไปก่อน ระหว่างที่ backend ยังไม่ส่ง can_cancel_order มาให้
      || state.orderData.can_cancel_order
    );
  },

  canRequestTaxInvoice: (state) => !!state.orderData.can_request_receipt,
  hasVat: (state) => !!state.orderData.has_vat,

  // สามารถแก้ไข ข้อมูลสำหรับออกใบกำกับได้มั้ย
  canEditReceiptContact: state => !!state.orderData.can_edit_receipt_contact,

  hasSpecifiedReceiptData: state => !!(
    state.orderData.receipt_id
    || state.orderData.receipt_id === false // ในกรณีที่เป็น Guest และบันทึก receipt แล้ว receipt_id จะเป็น false
  ),
  hasSpecifiedSenderData: state => !!(
    state.orderData.dealer
  ),

  // ต้อง Join ก่อนใช้ point ของร้านไหม
  isRequiredJoinToRedeemShopPoint: state => !!state.orderData.is_required_join_to_redeem_shop_point,

  // สามารถใช้ lnwPoint มาลดราคาได้มั้ย
  canRedeemLnwPoint: state => !!state.orderData.can_redeem_lnwpoint,

  // สามารถใช้ point ของร้านมาลดราคาได้มั้ย
  canRedeemShopPoint: state => !!state.orderData.can_redeem_shop_point,

  // Point ที่จะได้รับหากสั่งซื้อสำเร็จ
  receiveShopPoint: state => !!state.orderData.receive_shop_point,

  // สามารถกดยืนยันรับสินค้าได้หรือไม่
  canConfirmReceived: state => !!state.orderData.can_confirm_received,

  canReview: state => !!state.orderData?.can_review,
  reviewUrl: state => state.orderData?.review_url,

  // พร้อมที่จะจ่ายเงินแล้วหรือยัง
  isPayable: (state) => {
    return state.orderData.order_status === 'wait_payment' && state.orderData.payment_provider;
  },

  isPaymentMethodInputValueReady(state) {
    return state.form.paymentMethodInputValue
      && state.form.paymentMethodInputValue.selectedMethod
      && (
        typeof state.form.paymentMethodInputValue.ready === 'undefined'
        || state.form.paymentMethodInputValue.ready === true
      );
  },

  // กำลังเลือก payment method & provider รึเปล่า
  isSelectingPaymentMethodAndProvider(state, getters) {
    // ลูกค้ากด "เปลี่ยน" เพื่อกลับมาเลือกใหม่
    if (getters.isPreferToChangePaymentMethod)
      return true;

    if (state.orderData.order_status === 'wait_order')
      return true;

    // ในสถานะ wait_payment ถ้ายังเลือกไม่เสร็จ หมายถึงกำลังเลือก
    if (getters.isOrderWaitPayment && !getters.isBankTransferPaymentProvider) {
      return true;
    }

    // ถ้า empty(cart.payment_provider) ยังไงเราก็ต้องบังคับให้ลูกค้าเลือก
    if (state.orderData.order_status === 'wait_payment' && !state.orderData.payment_provider) {
      return true;
    }

    // ถ้ายังไม่มีช่องทางชำระเงินที่เลือกไว้ ก็จะบังคับว่าอยู่ใน state ที่ต้องเลือก payment method
    // noinspection RedundantIfStatementJS
    if (
      state.form.paymentMethodInputValue === null
      ||
      // ถ้าไม่ได้ระบุ selectedMethod ก็คือยังไม่ได้เลือกว่าจะจ่ายด้วยช่องทางไหน
      (typeof state.form.paymentMethodInputValue === 'object'
        && !state.form.paymentMethodInputValue.selectedMethod)
    ) {
      return true;
    }

    return false;
  },
  isPreferToChangePaymentMethod: (state) => state.form.isPreferToChangePaymentMethod,

  isOrderWaitPayment: state => !!(state.orderData.order_status === 'wait_payment' && state.orderData.payment_provider),

  preferReceiptButNotSelectContact: (state, getters) => state.form.receiptCheckboxChecked && !getters.hasSpecifiedReceiptData,

  // เอาไว้บอกว่า แต่ละ step ถูกย่ออยู่หรือเปล่า
  isExpandBuyerInfo: (state, getters) => state.stepMetas[getters.stepMapping.buyerInfo].open,
  isExpandShippingMethod: (state, getters) => state.stepMetas[getters.stepMapping.shippingMethod].open,
  isExpandPaymentMethod: (state, getters) => state.stepMetas[getters.stepMapping.paymentMethod].open,

  isPassStep1: (state, getters) => {
    for (let name in getters.stepMapping) {
      if (getters.stepMapping[name] === 1) {
        return eval("getters.isPassStep" + (name.charAt(0).toUpperCase() + name.slice(1)));
      }
    }
    return false;
  },
  isPassStep2: (state, getters) => {
    for (let name in getters.stepMapping) {
      if (getters.stepMapping[name] === 2)
        return eval("getters.isPassStep" + (name.charAt(0).toUpperCase() + name.slice(1)));
    }
    return false;
  },
  isPassStep3: (state, getters) => {
    for (let name in getters.stepMapping) {
      if (getters.stepMapping[name] === 3)
        return eval("getters.isPassStep" + (name.charAt(0).toUpperCase() + name.slice(1)));
    }
    return false;
  },

  canEditStep1: (state, getters) => {
    for (let name in getters.stepMapping) {
      if (getters.stepMapping[name] === 1)
        return eval("getters.canEdit" + (name.charAt(0).toUpperCase() + name.slice(1)));
    }
    return false;
  },
  canEditStep2: (state, getters) => {
    for (let name in getters.stepMapping) {
      if (getters.stepMapping[name] === 2)
        return eval("getters.canEdit" + (name.charAt(0).toUpperCase() + name.slice(1)));
    }
    return false;
  },
  canEditStep3: (state, getters) => {
    for (let name in getters.stepMapping) {
      if (getters.stepMapping[name] === 3)
        return eval("getters.canEdit" + (name.charAt(0).toUpperCase() + name.slice(1)));
    }
    return false;
  },

  isPaidOrPaymentInformed: (state) => {
    if (state.orderData) {
      if (state.orderData.acceptpayment_time)
        return true;

      // noinspection RedundantIfStatementJS
      if (state.orderData.informpayment_time)
        return true;

      if (state.orderData.is_cod) {
        return true;
      }
    }

    return false;
  },

  isProtectedByLnwPay: (state) => !!state.orderData?.lnwpay_flag && !["0", "false", "FALSE"].includes(state.orderData?.lnwpay_flag),

  isLnwPayPaymemt: (state) => {
    const current_provider = state.orderData?.payment_provider;

    // console.log(current_provider);

    if (!current_provider)
      return null;

    let is_lnwpay = null;

    // noinspection JSUnresolvedVariable
    for (const method of state.orderData.payment_methods) {
      if (Array.isArray(method.providers)) {
        for (const provider of method.providers) {
          if (provider.key === current_provider) {
            if(typeof provider.is_lnwpay !== 'undefined') {
              is_lnwpay = provider.is_lnwpay;
              break;
            }
          }
        }
        if (is_lnwpay !== null)
          break;
      }
    }

    if(is_lnwpay !== null) {
      return is_lnwpay;
    }

    return !!state.orderData.lnwpay_flag;
  },

  isShopClose: (state) => state.shopData?.is_close,

  isShopIsTestShop: state => state.shopData?.is_test,

  isTrackBeginCheckoutByRedirect: state => !!(state.orderData?.should_redirect),
  hasToRedirectToTrackBeginCheckout: (state, getters) => getters.isTrackBeginCheckoutByRedirect &&
    state.type === 'order' &&
    state.order_id &&
    state.secret,
  trackBeginCheckoutOnShopWebsiteURL: state => {
    return shopUrlForTrackEvent(state, "begin_checkout");
  },

  isTrackingPurchaseByRedirect: (state, getters) => getters.isTrackBeginCheckoutByRedirect,
  trackPurchaseOnShopWebsiteURL: state => {
    return shopUrlForTrackEvent(state, "purchase");
  },

  preferredUserResources: (state, getters) => {
    const preferred_data = [];
    if (getters.canEditReceiptContact)
      preferred_data.push('taxAddresses');
    if (getters.canEditReceiverContact){
      preferred_data.push('receiverAddresses');
      if(getters.isDropshipActive){
        preferred_data.push('dealerReceiverAddresses');
        preferred_data.push('senderAddresses');
      }
    }

    return preferred_data;
  },

  // order_uuid ใช้ อ้างอิง สำหรับใน LnwPay
  orderUUID: state => state.orderData.order_uuid,

  lnwpay_us: state => state.orderData.lnwpay_us,
  lnwshop_s: state => state.secret,

  signal_currency: () => 'THB',
  signal_value: (state) => state.orderData.price?.beforeshipping ? state.orderData.price?.beforeshipping : false,
  signal_shipping_cost: (state) => state.orderData.price?.shipping ? state.orderData.price?.shipping : false,
  signal_coupon: (state) => {
    let coupon_array = [];
    if(state.orderData?.coupons && Array.isArray(state.orderData.coupons) && state.orderData.coupons.length > 0){
      state.orderData.coupons.forEach(function(coupon){
        if(coupon.code){
          coupon_array.push(coupon.code)
        }else if(coupon.config && coupon.config.name){
          coupon_array.push(coupon.config.name)
        }
      });
    }
    if(state.orderData.coupons_names) {
      state.orderData.coupons_names.forEach(function(name){
        coupon_array.push(name);
      });
    }
    if(state.orderData.coupons_codes) {
      state.orderData.coupons_codes.forEach(function(code){
        coupon_array.push(code);
      });
    }
    if(coupon_array.length == 0){
      return false;
    }
    return coupon_array.join(',');
  },

  // https://developers.google.com/gtagjs/reference/ga4-events#add_payment_info_item
  signal_google_items_for_shop: state => {
    return state.orderData.items.map(shop_product_to_google_item);
  },
  signal_google_items_for_mall: state => {
    return state.orderData.items.map(mall_product_to_google_item);
  },

  signal_facebook_content_ids: (state) => {
    const content_ids = [];

    if (Array.isArray(state.orderData.items)) {
      for (const item of state.orderData.items) {
        if (item.fb_product_id) {
          content_ids.push(item.fb_product_id);
        }
      }
    }

    if (content_ids.length === 0)
      return false;

    return content_ids;
  },
  signal_facebook_contents: (state) => {
    const contents = [];

    if (Array.isArray(state.orderData.items)) {
      for (const item of state.orderData.items) {
        if (item.fb_product_id) {
          contents.push({
            id: item.fb_product_id,
            quantity: item.quantity,
          });
        }
      }
    }

    if (contents.length === 0)
      return false;

    return contents;
  },
  signal_facebook_content_category: (state) => {
    // ถ้าสินค้าอยู่ในหมวดหมู่เดียว จะตอบชื่อหมวดหมู่ออกไปให้
    let category = null;

    if (Array.isArray(state.orderData.items)) {
      for (const item of state.orderData.items) {
        if (category === null) {
          if (item.category) {
            category = item.category;
          }
        } else {
          if (item.category !== category) {
            return false; // มีมากกว่า 1 หมวดหมู่
          }
        }
      }
    }

    return category ? category : false;
  },
  signal_google_payment_type: (state) => {
    const current_provider = state.orderData?.payment_provider;

    // console.log(current_provider);

    if (!current_provider)
      return false;

    if (current_provider === 'credit_card')
      return 'Credit Card';

    let type = false;

    // noinspection JSUnresolvedVariable
    for (const method of state.orderData.payment_methods) {
      if (Array.isArray(method.providers)) {
        for (const provider of method.providers) {
          if (provider.key === current_provider) {
            type = method.key;
            break;
          }
        }
        if (type)
          break;
      }
    }


    if (type) {
      ucwords(type.replace('_', ' '));
    }

    return false;
  },
  signal_google_shipping_tier: (state, getters) => (getters.selectedShipping && getters.selectedShipping.name) ? getters.selectedShipping.name : false,

  // LnwPay callback states
  lnwpayCallbackStatesJustEntered: (state, getters) => {
    if (getters.lnwpayCallbackStates)
      return state.lnwpayCallbackStatesJustEntered;
    else
      return false;
  },
  lnwpayCallbackStates: (state) => {
    const nowTimestamp = Math.floor(new Date().getTime() / 1000);
    if (state.lnwpayCallbackStates?.pay_t > nowTimestamp - config_lnwpay.sync_order_status_timeout_seconds) {
      return state.lnwpayCallbackStates;
    } else {
      return null;
    }
  },
};